<template>
  <div>
    <section id="register" class="">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <div class="navy-line"></div>
            <h1>Registro</h1>
            <p>
              Llena el formulario para iniciar en tu nuevo empleo.
            </p>
          </div>
        </div>
      </div>
    </section>
    <RegisterForm />
  </div>
</template>

<script>
import RegisterForm from "../sections/RegisterForm.vue";
export default {
  components: {
    RegisterForm,
  },
};
</script>

<style scoped>
.navy-line {
  margin-top: 0;
}
</style>
