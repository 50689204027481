<template>
  <section id="form-register" class="form">
    <div class="container">
      <div class="row m-b-lg">
        <div class="panel panel-default">
          <div class="panel-body">
            <RequestLoader v-if="loading" />
            <div v-if="loading" class="loading-margin"></div>

            <form
              v-if="!loading"
              class="form-horizontal"
              @submit.prevent="submitForm"
            >
              <div class="form-group">
                <label for="input__name" class="col-sm-2 control-label"
                  >Nombre(s)</label
                >
                <div class="col-sm-10">
                  <input
                    v-model="name"
                    type="text"
                    class="form-control"
                    id="input__name"
                    placeholder="J. Antonio"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="input__flastname" class="col-sm-2 control-label"
                  >Apellido Paterno</label
                >
                <div class="col-sm-10">
                  <input
                    v-model="flastname"
                    type="text"
                    class="form-control"
                    id="input__flastname"
                    placeholder="González"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="input__slastname" class="col-sm-2 control-label"
                  >Apellido Materno</label
                >
                <div class="col-sm-10">
                  <input
                    v-model="slastname"
                    type="text"
                    class="form-control"
                    id="input__slastname"
                    placeholder="López"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="input__yearsold" class="col-sm-2 control-label"
                  >Edad</label
                >
                <div class="col-sm-10">
                  <input
                    v-model="yearsold"
                    type="number"
                    class="form-control"
                    id="input__yearsold"
                    placeholder="32"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="input__address" class="col-sm-2 control-label"
                  >Domicilio</label
                >
                <div class="col-sm-10">
                  <input
                    v-model="address"
                    type="text"
                    class="form-control"
                    id="input__address"
                    placeholder="Colonia, Municipio, Estado"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="input__grade" class="col-sm-2 control-label"
                  >Grado de estudios</label
                >
                <div class="col-sm-10">
                  <select
                    v-model="grade"
                    class="form-control"
                    id="input__grade"
                  >
                    <option>-- Seleccione --</option>
                    <option
                      v-for="(grade, index) in grades"
                      :key="index"
                      :value="grade"
                      >{{ grade }}</option
                    >
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label for="input__phone" class="col-sm-2 control-label"
                  >Teléfono</label
                >
                <div class="col-sm-10">
                  <input
                    v-model="phone"
                    type="tel"
                    class="form-control"
                    id="input__phone"
                    placeholder="3112114121"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="input__mail" class="col-sm-2 control-label"
                  >Correo</label
                >
                <div class="col-sm-10">
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    id="input__mail"
                    placeholder="tu@email.com"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="input__aboutme" class="col-sm-2 control-label"
                  >Cuentanos de ti</label
                >
                <div class="col-sm-10">
                  <textarea
                    v-model="aboutme"
                    class="form-control"
                    rows="3"
                    id="input__aboutme"
                    :placeholder="`${aboutme_placeholder} ...`"
                  ></textarea>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" v-model="terms" /> Acepto los
                      términos y condiciones
                    </label>
                  </div>
                  <router-link :to="{ name: 'privacyagreement' }"
                    >Aviso de Privacidad</router-link
                  >
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <button type="submit" class="btn btn-primary">Enviar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RequestLoader from "../components/RequestLoader.vue";
export default {
  data() {
    return {
      grades: [
        "Primaria trunca",
        "Primaria",
        "Secundaria trunca",
        "Secundaria",
        "Bachillerato trunco",
        "Bachillerato",
        "Bachillerato Técnico trunco",
        "Bachillerato Técnico",
        "Licenciatura trunca",
        "Licenciatura",
      ],
      loading: false,
      terms: false,
      name: null,
      flastname: null,
      slastname: null,
      yearsold: null,
      address: null,
      grade: "-- Seleccione --",
      email: null,
      phone: null,
      aboutme: null,
      aboutme_placeholder:
        "Tengo licencia de condicir tipo A, manejo también motocicletas, me gusta vender ...",
      success: "",
      response: "",
      errors: [],
    };
  },
  created() {
    if (this.$route.params.puesto) {
      this.aboutme = `Me interesa el puesto de ${this.$route.params.puesto} en ${this.$route.params.zona}, ${this.$route.params.estado} \n [Detalle su solicitud] ... `;
    }
  },
  methods: {
    submitForm() {
      if (this.terms) {
        this.errors = [];
        this.response = "";
        this.loading = true;
        const options = {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        };
        this.axios
          .post(
            "https://us-central1-accyon.cloudfunctions.net/app/sendMail",
            {
              name: this.name,
              flastname: this.flastname,
              slastname: this.slastname,
              yearsold: this.yearsold,
              address: this.address,
              grade: this.grade,
              email: this.email,
              phone: this.phone,
              aboutme: this.aboutme,
            },
            options
          )
          .then((response) => {
            this.loading = false;
            if ([200, 202].includes(response.status)) {
              if (
                [200, 202].includes(response.data.code) ||
                [200, 202].includes(response.data.status)
              ) {
                this.response = "Su mensaje ha sido recibido";
                this.$swal({
                  icon: "success",
                  title: "Todo salió bien",
                  text: this.response,
                });
              } else {
                this.errors = response.data.response.body.errors;
                this.response = "Sucedio un problema al enviar sus datos";
                this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: this.response,
                });
              }
            } else {
              this.errors = response.errors;
            }
          })
          .catch((error) => {
            this.loading = false;
            this.response = "Error: " + error.message;
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: this.response,
            });
          });
        this.name = null;
        this.flastname = null;
        this.slastname = null;
        this.yearsold = null;
        this.address = null;
        this.grade = "-- Seleccione --";
        this.email = null;
        this.phone = null;
        this.aboutme = null;
        this.terms = false;
      } else {
        this.$swal({
          icon: "warning",
          title: "Cuidado...",
          text: "Favor de aceptar los términos y condiciones",
        });
      }
    },
  },
  components: {
    RequestLoader,
  },
};
</script>
